<template>
    <v-row>
        <v-col cols="12" lg="7" xl="7">
            <div class="normal-card bg-contcolor">
                <div class="detail-item">
                    <div class="leaflet-area pos-relative">
                        <div id="leafletmap"></div>
                    </div>
                </div>
            </div>
            <div class="normal-card bg-contcolor">
                <h2 class="m-title pa-md-6 pa-2 mb-0">
                    <div class="txt">
                        上傳率不良名單({{tolerate_total}}台)
                    </div> 
                </h2>

                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th class="text-center">
                                區域
                            </th>
                            <th class="text-center">
                                類型
                            </th>
                            <th class="text-center">
                                地址
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="option in tolerate_list" :key="option.device">
                            <td>{{option.device}}</td>
                            <td class="text-center">
                                {{option.area}}
                            </td>
                            <td class="text-center">
                                {{option.areatype}}
                            </td>
                            <td class="text-center">
                                {{option.road}}
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
            
        </v-col>
        <v-col cols="12" lg="5" xl="5">
            <v-row>
              <v-col cols="12" md="6" class="mb-5">
                <div class="normal-card bg-contcolor">
                    <h2 class="m-title pa-md-3 pa-1 mb-0">
                        <div class="txt">
                            達標率
                            <div class="small-txt">
                                {{tilNow_real_total_data}}/{{tilNow_total_data}}
                            </div>
                        </div> 
                    </h2>
                    <div class="echartbja-chart echart-limit-height" id="echartbjaS-chart"></div>
                </div><!-- /.normal-card -->
              </v-col>
              <v-col cols="12" md="6" class="mb-5">
                <div class="normal-card bg-contcolor">
                    <h2 class="m-title pa-md-3 pa-1 mb-0">
                        <div class="txt">
                            異常率
                            <div class="small-txt">
                                {{tilNow_real_abnormal_total_data}}/{{tilNow_total_data}}
                            </div>
                        </div> 
                    </h2>
                    <div class="echartbja-chart echart-limit-height" id="echartbjaF-chart"></div>
                </div><!-- /.normal-card -->
              </v-col>
            </v-row>
            
            <div class="normal-card bg-contcolor">
                <h2 class="m-title pa-md-6 pa-2 mb-0">
                    <div class="txt">
                        異常名單({{ab_total}}台)
                    </div> 
                </h2>

                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th class="text-center">
                                區域
                            </th>
                            <th class="text-center">
                                時間
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(option,index) in ab_list" :key="option.device + '_' + index">
                            <td>{{option.device}}</td>
                            <td class="text-center">
                                {{option.areatype}}
                            </td>
                            <td class="text-center">
                                {{option.latest_time}}
                            </td>
                        </tr>
                      
                    </tbody>
                    </template>
                </v-simple-table>

            </div><!-- /.normal-card -->

            <!--<v-card class="normal-card no-pa">
                <h2 class="m-title pa-md-6 pa-2 mb-0">
                    <div class="txt">
                        可疑污染源測站資料
                    </div> 
                </h2>
                <v-simple-table class="scroll-auto">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-center"><div class="w-1">污染類型</div></th>
                            <th class="text-center"><div class="w-1">污染最大值</div></th>
                            <th class="text-center"><div class="w-1">開始時間</div></th>
                            <th class="text-center"><div class="w-1">處理人</div></th>
                            <th class="text-center"><div class="w-1">處理狀況</div></th>
                            <th class="text-center"><div class="w-1">結束時間</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-id="TCCCC">
                            <td class="text-center">TC1616</td>
                            <td class="text-center">TVOC</td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 09:48:18</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 10:48:20</td>
                        </tr>
                        <tr data-id="TCCCC">
                            <td class="text-center">TC1616</td>
                            <td class="text-center">TVOC</td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 09:48:18</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 10:48:20</td>
                        </tr>
                        <tr data-id="TCCCC">
                            <td class="text-center">TC1616</td>
                            <td class="text-center">TVOC</td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 09:48:18</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center">2022-03-07 10:48:20</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card>-->
            <!-- /.normal-card -->
            
        </v-col>
    </v-row>  
</template>
<script>
import locationIcon from '@/assets/images/all/icon_location.svg';
import locationIcon_red from '@/assets/images/all/icon_location_red.svg';
import locationIcon_yellow from '@/assets/images/all/icon_location_yellow.svg';
import locationIcon_orange from '@/assets/images/all/icon_location_orange.svg';
import locationIcon_purple from '@/assets/images/all/icon_location_purple.svg';
import locationIcon_darkred from '@/assets/images/all/icon_location_darkred.svg';
import locationIcon_epa from '@/assets/images/all/icon_location_epa.svg';
import locationIcon_gray from '@/assets/images/all/icon_location_gray.svg';
import axios from 'axios';
import config from '@/config.js';

var dataArry=[];
var marker_basic;

var dashboard_success_Chart = null;
var dashboard_fail_Chart = null;

export default{
    name: 'Dashboard',
    data: () => ({
        station_id:"",
        tilNow_total_data:0,
        tilNow_real_total_data:0,
        tilNow_real_abnormal_total_data:0,
        realSumList:[],
        tolerate_list:[],
        tolerate_total:0,
        ab_list:[],
        ab_total:0
        /*pmtwo:{
            val:"",
            color:"gray",
        }*/

    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(5)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){

        var self=this;// assign context to self variable
        $(function() {
            $(".nav_mainTitle").text("儀錶板");
        });


        //00:00 ~ 目前時間
        var now_time = new Date();
        var nowdate = now_time.getFullYear() + "/" + (now_time.getMonth() + 1) + "/" + now_time.getDate();
        var today_begin_time = new Date(nowdate + " 00:00:00");   
        //var diff_time = ((Math.abs(now_time.getTime() - today_begin_time.getTime()))/((1000 * 60 * 60))); //小時
        var diff_time = ((Math.abs(now_time.getTime() - today_begin_time.getTime()))/((1000 * 60))); //分

        var nowdatetime = now_time.getFullYear() + "/" + (now_time.getMonth() + 1) + "/" + now_time.getDate() + " " + now_time.getHours() + ":" + now_time.getMinutes() + ":" + now_time.getSeconds();
        var todaybegintime = today_begin_time.getFullYear() + "/" + (today_begin_time.getMonth()+1) + "/" + today_begin_time.getDate() + " 00:00:00";
        
        //測試
        /*var nowdatetime = "2022/08/10 10:10:00";
        var todaybegintime = "2022/08/10 00:00:00";*/

        /*
        var now_time = new Date();
        var nowdate = now_time.getFullYear() + "/" + now_time.getMonth() + "/" + now_time.getDate();
        var nowdatetime = now_time.getFullYear() + "/" + now_time.getMonth() + "/" + now_time.getDate() + " " + now_time.getHours() + ":" + now_time.getMinutes() + ":" + now_time.getSeconds();
        var today_begin_time = new Date(nowdate + " 00:00:00");        
        var todaybegintime = today_begin_time.getFullYear() + "/" + today_begin_time.getMonth() + "/" + today_begin_time.getDate() + " 00:00:00";
        //先轉成分鐘
        var diff_time = ((Math.abs(now_time.getTime() - today_begin_time.getTime()))/((1000 * 60 * 60)));

        console.log(diff_time);*/

        /*this.tilNow_total_data = diff_time;*/
        
            const api_totaldevice_url=config.geturl(config.API.infostation,{});
            const api_real_url=config.geturl(config.API.realtime,{limit:3000});
            const apiurl_realsum=config.geturl(config.API.info_real_sum,{stime:todaybegintime,etime:nowdatetime});
            let promises = [];
            promises.push(
                //總感測器
                axios.get(api_totaldevice_url).then(response => {
                    if(response.data != "" && response.data != null){
                        var tilto_real_count = response.data.length;
                        this.tilNow_total_data = tilto_real_count;
                    }
                })
            )
            promises.push(
                //即時數據
                axios.get(api_real_url).then(response => {
                    if(response.data != "" && response.data != null){
                        var tilto_real_count = response.data.length;
                        this.tilNow_real_total_data = tilto_real_count;
                        var total_data = this.tilNow_total_data;
                        this.tilNow_real_abnormal_total_data =(tilto_real_count < total_data)?(total_data - tilto_real_count):0
                        
                    }
                })
            )

            Promise.all(promises).then(() => {
                //儀錶板
                dashboard_success_Chart = echarts.init(document.getElementById('echartbjaS-chart'));
                dashboard_fail_Chart = echarts.init(document.getElementById('echartbjaF-chart'));

                var acheive_rate=0;
                var abnormal_rate=0;
                acheive_rate = roundToTwo((this.tilNow_real_total_data / this.tilNow_total_data))*100;
                abnormal_rate = (acheive_rate <= 100)?(100-acheive_rate):0;
                darwEchart(dashboard_success_Chart,acheive_rate);
                darwEchart(dashboard_fail_Chart,abnormal_rate);
            });



        /*
        diffTime(time1, time2) {
                // 取絕對值 在轉換成天數
                return ((Math.abs(time1 - time2)) / (1000 * 60 * 60 * 24))
            },

        */

            
        axios.get(apiurl_realsum).then(response => {
                var arry = response.data;
                if(arry != null && arry.length > 0){
                    this.realSumList = arry;

                const apiurl=config.geturl(config.API.infostation,{});
                axios.get(apiurl).then(response => {
                    var map;
                    var marker;
                    var polygon;
                    var overLatlng;
                    var overRadius=200;
                    
                    var map = L.map('leafletmap').fitWorld();
                    map.locate({setView: true, maxZoom: 16});
                    map.on('locationfound', function(e){
                        var radius = e.accuracy;
                        L.marker(e.latlng).addTo(map)
                            .bindPopup("系統預設位置").openPopup();
                        //L.circle(e.latlng, radius).addTo(map);
                        overLatlng = e.latlng;
                        overRadius = radius;
                    });
                    map.on('locationerror', function(){
                        //不允許就定位在中區
                        var radius = 1952.9697806214715;
                        L.marker([24.1746431,120.6774942]).addTo(map)
                            .bindPopup("系統預設位置").openPopup();
                        //L.circle([24.1746431,120.6774942], radius).addTo(map);
                        overLatlng = [24.1746431,120.6774942];
                        overRadius = radius;

                        map.setView([24.1746431,120.6774942],13);
                    });

                    //全螢幕
                    map.addControl(new L.Control.Fullscreen());
                    var markerlayerGroup = L.layerGroup().addTo(map);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '<a href="https://www.openstreetmap.org/">OpenStreetMap</a> | <a href="https://noob.tw/openstreetmap/">Tutorial 教學</a>',
                        maxZoom: 18,
                    }).addTo(map);
                    var LeafIcon = L.Icon.extend({
                        options: {
                            iconSize: [15, 15],
                        iconAnchor: [0, 0],
                        popupAnchor: [0, 0]
                        }
                    });
                    var greenIcon = new LeafIcon({iconUrl: locationIcon}),
                        redIcon = new LeafIcon({iconUrl: locationIcon_red}),
                        yellowIcon = new LeafIcon({iconUrl: locationIcon_yellow}),
                        orangeIcon = new LeafIcon({iconUrl: locationIcon_orange}),
                        purpleIcon = new LeafIcon({iconUrl: locationIcon_purple}),
                        darkredIcon = new LeafIcon({iconUrl: locationIcon_darkred}),
                        epaIcon = new LeafIcon({iconUrl: locationIcon_epa}),
                        grayIcon = new LeafIcon({iconUrl: locationIcon_gray});

                

                    //全部出現
                    dataArry=response.data;
                    var i=0;
                    var now_date = new Date();
                    const formatDate = (current_datetime)=>{
                        let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds();
                        return formatted_date;
                    }
                    var now_datetime=formatDate(now_date);

                    $.each(dataArry, function() {
                        var iconType=grayIcon;
                        if(dataArry[i].lat !== "" && dataArry[i].lat !== null && typeof(dataArry[i].lat) !== "undefined" && dataArry[i].lon !== "" && dataArry[i].lon !== null && typeof(dataArry[i].lon) !== "undefined"){
                            marker_basic = L.marker([dataArry[i].lat,dataArry[i].lon], {icon: iconType,title:dataArry[i].id}).addTo(markerlayerGroup);
                        }
                        //var realSum_arry = 
                        var realSum_single_arry = self.realSumList.filter(item=> item.id == dataArry[i].device);
                        var realSum_single_sum=0;
                        if(realSum_single_arry.length > 0){
                            realSum_single_sum = realSum_single_arry[0].sum;
                        }
                        var real_sum_rate = roundToTwo(realSum_single_sum/diff_time)*100;
                        

                        marker_basic.bindPopup("<div class='lc-title'>感測器：" + dataArry[i].device + "(" + real_sum_rate + "%)</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>經度</sub>：" + dataArry[i].lat + "<br/>緯度：" + dataArry[i].lon + "<br/>地區：" + dataArry[i].area + "<br/>類型：" + dataArry[i].areatype + "</div><div class='col-md-6 col-12 item'>" + "目標：" + dataArry[i].target + "<br>地標：" + dataArry[i].landmark + "<br>地址：" + dataArry[i].area + "<br>鎮/區：" + dataArry[i].town + "</div><div class='col-12 item'><div class='hint'>更新時間：" + now_datetime + "</div></div></div>")

                        marker_basic.on('click', function (e) {
                            /*$(".leaflet-marker-icon").removeClass("selectedMarker");
                            $(e.target._icon).addClass('selectedMarker');*/
                    
                    }).addTo(map);

                        markerlayerGroup.addLayer(marker_basic);
                        i++;

                    });

                });

                }
            })

       

        

        
        //上傳不良率
        const apiurl_tolerate=config.geturl(config.API.info_tolerate,{});
        axios.get(apiurl_tolerate).then(response => {
            let arry = response.data;
            if(arry != null && arry.length > 0){
                this.tolerate_list = arry;
                this.tolerate_total = arry.length;
            }
        });

        //異常名單
        const apiurl_ablist=config.geturl(config.API.info_ablist,{});
        axios.get(apiurl_ablist).then(response => {
            let arry = response.data;
            if(arry != null && arry.length > 0){
                this.ab_list = arry;
                this.ab_total = arry.length;
            }
        });

    }

 
}

function darwEchart(echartObj,val){
    echartObj.setOption({
        series: [
            {
                type: 'gauge',
                progress: {
                    show: true,
                    width: 10
                },
                axisLine: {
                    lineStyle: {
                    width: 10
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    length: 5,
                    lineStyle: {
                    width: 2,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: 15,
                    color: '#999',
                    fontSize: 10
                },
                anchor: {
                    show: true,
                    showAbove: true,
                    size: 10,
                    itemStyle: {
                    borderWidth: 3
                    }
                },
                title: {
                    show: false
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 30,
                    offsetCenter: [0, '70%'],
                    color:'#CECECE'
                },
                data: [
                    {
                    value: val
                    }
                ]
            }
        ]
        });
}

</script>

